<template >
  <div class="containter">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了"> -->
      <div class="listCell" v-for="item in list" :key="item.id">
        <div class="listItem" @click="gotoDetail(item)">
          <div class="height12"></div>
          <div class="commentsAvtorArea">
            <div class="commentsAvtor">
              <img v-if="Avatar" :src="Avatar" alt />
              <img v-else src="../../assets/img/avatar.png" style="width:100%;height:100%;" alt />
            </div>
          </div>
          <div class="commentsContentArea">
            <div class="commetUserAndDate">
              <div class="fontName">{{item.applyName}}</div>
              <div class="fontDate">{{item.matterTypeName}}</div>
            </div>
            <div class="fontContent">{{item.content}}</div>
            <div
              class="commentsImgLine"
              v-if="item.contentImg && item.contentImg.split(',').length > 0"
            >
              <!-- <div class="commentsImgItem" v-for="imgItem in item.contentImg.split(',')" :key="imgItem.url"></div> -->
              <img
                class="commentsImgItem"
                v-for="(imgItem, index) in item.contentImg.split(',')"
                :key="index"
                :src="imgItem"
                alt
              />
            </div>
            <div class="dateLine">{{item.createTime}}</div>
            <div class="height15"></div>
          </div>
          <div class="baseLine"></div>
          <div class="bottomLine">
            <div v-if="item.status===0"></div>
            <div v-if="item.status===1" class="greyFontLeft">处理人：{{item.replyName}}</div>
            <div class="greyFont" v-if="item.status===1">已处理</div>
            <div class="redFont" v-if="item.status===0">未处理</div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="loading">加载中...</div>
      <div class="no-data" v-if="list.length === 0 && !loading">暂无数据</div>
      <div class="height100"></div>
      <!-- </van-list> -->
    </van-pull-refresh>
    <div class="wantofeedback" @click="gotoFeedback">
      <img :src="feedbackImg" style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import { getFeedBackList } from '@/api/feedback';
import { Notify } from 'vant';
export default {
  name: 'SFDashboardList',

  data() {
    return {
      // 任务的四种状态 1-待领取 2-进行中 3-已完成 4-已终止
      // 审核的两种状态 1-待审核 2-已审核
      feedbackImg: require('./imgs/wantToFeedback.png'),
      selectRow: 1,
      param: {
        page: 0,
        size: 5,
        total: 20
      },
      loading: false,
      finished: false,
      refreshing: false,
      fakeList: [],
      list: [],
      Avatar: {}
    };
  },
  watch: {},
  created() {
    this.getList();
    this.Avatar = localStorage.getItem('Avatar');
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getList() {
      //请求列表接口
      this.loading = true;
      getFeedBackList({ dtype: 1 }).then(res => {
        console.log(res);
        this.list = res.data;

        this.loading = false;
        this.refreshing = false;
      });
    },
    getRecords() {
      const this_ = this;
      const param = this.param;
      param.page += 1;
      const searchParam = { ...param };
      searchParam.status = this.selectRow;
      // 调接口

      let list = this_.list;
      list = list.concat(this_.fakeList);
      for (let i = 1; i <= list.length; i++) {
        list[i - 1].id = i;
      }
      this_.list = JSON.parse(JSON.stringify(list));
      console.log(this_.list);
      param.total = 10;
      this_.param = param;
      // 加载状态结束
      this_.loading = false;
      this_.refreshing = false;
      // 数据全部加载完成
      if (this_.list.length >= param.total) {
        this_.finished = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    // 查询的改变
    changeList(num) {
      this.selectRow = num;
      this.param.page = 0;
      this.finished = false;
      this.list = [];
      this.getRecords();
    },
    gotoFeedback() {
      this.$router.push({
        name: 'sfDashboardIWantTo'
      });
    },
    gotoDetail(row) {
      this.$router.push({
        name: 'sfDashboardDetail',
        query: { detail: row.id }
      });
    },
    getInputChange() {
      this.searchIconVisible = false;
    },
    blurChange() {
      this.searchIconVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-icon {
  display: flex;
  align-items: center;
}
/deep/ .van-cell {
  padding: 0px;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headSearchLine {
  width: 100%;
  height: 40 * $px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.searchInput {
  width: 355 * $px;
  height: 29 * $px;
  line-height: 29 * $px;
  border-radius: 14.5 * $px;
  background-color: #f5f5f5;
  padding: 0px;
}
.searchIcon {
  width: 12 * $px;
  height: 12 * $px;
  position: absolute;
  top: 13.5 * $px;
  left: 146 * $px;
  z-index: 1;
}
.radioButtonLine {
  height: 34 * $px;
  width: 100%;
  background-color: #ffffff;
}
.radioButtonItem {
  width: 33.33%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.radioButtonFont {
  font-size: 14 * $px;
  color: #555555;
  margin-bottom: 7 * $px;
}
.radioButtonBottomLine {
  width: 30 * $px;
  height: 2 * $px;
  border-radius: 0.5 * $px;
  background-color: #ffffff;
}
.listItem {
  width: 100%;
  overflow: auto;
  position: relative;
}
.listCell {
  margin: 10 * $px 10 * $px 0 10 * $px;
  width: 355 * $px;
  background-color: #ffffff;
}
.commentsAvtorArea {
  width: 45 * $px;
  float: left;
  display: flex;
  justify-content: center;
}
.commentsAvtor {
  width: 33 * $px;
  height: 33 * $px;
  background: grey;
  overflow: hidden;
  border-radius: 16.5 * $px;
  margin-left: 12 * $px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.commentsContentArea {
  margin-left: 8 * $px;
  width: 298 * $px;
  float: left;
}
.commetUserAndDate {
  height: 15 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontName {
  color: #555555;
  font-size: 14 * $px;
}
.fontDate {
  color: #999999;
  font-size: 11 * $px;
  margin-right: 10 * $px;
}
.fontContent {
  margin: 8 * $px 0 0 0;
  color: #222222;
  font-size: 14 * $px;
}
.commentsBottomLine {
  width: 100%;
  height: 0.5 * $px;
  background-color: #eeeeee;
  margin-top: 15 * $px;
}
.commentsImgLine {
  width: 100%;
  word-break: break-all;
  overflow: auto;
}
.commentsImgItem {
  height: 70 * $px;
  width: 70 * $px;
  margin-top: 9 * $px;
  margin-right: 10 * $px;
  background-color: #dddddd;
  overflow: hidden;
  float: left;
}
.height15 {
  width: 100%;
  height: 15 * $px;
}
.height12 {
  width: 100%;
  height: 12 * $px;
}
.dateLine {
  width: 100%;
  height: 13 * $px;
  font-size: 12 * $px;
  color: #999999;
  margin-top: 10 * $px;
}
.baseLine {
  width: 331 * $px;
  height: 0 * $px;
  border-bottom: #eeeeee 0.6 * $px solid;
  margin-left: 12 * $px;
  float: left;
}
.bottomLine {
  width: 100%;
  height: 35 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.greyFont {
  font-size: 12 * $px;
  color: #999999;
  margin-right: 10 * $px;
}
.redFont {
  margin-right: 10 * $px;
  font-size: 12 * $px;
  color: #ff192f;
}
.greyFontLeft {
  font-size: 12 * $px;
  color: #222222;
  margin-left: 10 * $px;
}
.wantofeedback {
  width: 71.5 * $px;
  height: 71.5 * $px;
  position: fixed;
  right: 14 * $px;
  bottom: 14 * $px;
}
.no-data {
  padding: 40 * $px;
  text-align: center;
  font-size: 20 * $px;
}
.height100 {
  width: 100%;
  height: 200 * $px;
}
</style>



